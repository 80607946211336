export interface PageParams<T extends string = string, K extends string = string> {
  params: {
    [key in T]: K;
  };
}

export interface PageSearchParams<T extends string = string, K extends string = string> {
  searchParams: {
    [key in T]: K;
  };
}

export type PageSlugParams = PageParams<'slug'>;

/**
 * Checkout feature
 */
export enum CheckoutStep {
  ABOUT_YOU = 'about-you',
  BOOKING = 'booking',
  PAYMENT = 'payment',
}

/**
 * Qualification feature
 */
export enum ConsultationType {
  ASYNC = 'ASYNC',
  SYNC = 'SYNC',
}

export type QualificationStatus = ConsultationType | 'DISQUALIFIED';

export enum QualificationRuleType {
  CONTAINS = 'contains',
  EQUALS = 'equals',
  NOT_CONTAINS = 'notContains',
  NOT_EQUALS = 'notEquals',
  BMI_LESS_THAN = 'bmiLessThan',
}

export interface QualificationRule {
  customModuleId: string;
  type: QualificationRuleType;
  value: string | string[];
}

type QualificationRuleSet = QualificationRule[];

export type QualificationRules = {
  [status in QualificationStatus]?: (QualificationRule | QualificationRuleSet)[];
};

export interface QualificationFormData {
  [slug: string]: {
    formId: string;
    qualificationRules: QualificationRules;
  };
}

// TO-DO: add other conditions that require checkout flow
export enum AgileProduct {
  WEIGHT_LOSS = 'weight-loss',
}

export enum AgileTreatment {
  SEMAGLUTIDE = 'semaglutide',
  ORAL_SEMAGLUTIDE = 'oral-semaglutide',
}

export enum MedicationCode {
  SEMAGLUTIDE = 'sem',
  ORAL_SEMAGLUTIDE = 'osg',
}
